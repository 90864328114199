import React, { useCallback, useMemo, useState } from 'react'
import ReactDOMServer from 'react-dom/server'
import { useTranslation } from 'react-i18next'

import { Objects } from 'utils/objects'

import { CountryIso } from 'meta/area'
import { TooltipId } from 'meta/tooltip'

import { useIsPanEuropeanRoute } from 'client/hooks'
import { OptionsGroup } from 'client/components/Inputs/Select'

import { useCountriesByRegionOptions } from './useCountriesByRegionOptions'

interface Props {
  value: Array<CountryIso>
  error?: string
}

interface Returned {
  hideTooltip: () => void
  showTooltip: () => void
  tooltipContent: string | null
  dataTooltipId: TooltipId
}

export const useTooltipContent = (props: Props): Returned => {
  const { value, error } = props
  const [canDisplayTooltip, setCanDisplayTooltip] = useState<boolean>(true)
  const { t } = useTranslation()

  const countryOptionGroups = useCountriesByRegionOptions()
  const isPanEuropean = useIsPanEuropeanRoute()

  const tooltipContent = useMemo<string | null>(() => {
    if (Objects.isEmpty(value)) return null
    if (!canDisplayTooltip) return null

    if (error) {
      return ReactDOMServer.renderToStaticMarkup(
        <div className="regions-container" style={{ gridTemplateColumns: '1fr' }}>
          <div className="countries-container">
            <span className="country">{error}</span>
          </div>
        </div>
      )
    }

    const selectedRegions: Array<{
      regionLabel: string
      selectedCountries: Array<string>
    }> = []

    if (isPanEuropean) {
      selectedRegions.push({
        regionLabel: '', // Not visible when there is only one region
        selectedCountries: value
          .map((countryIso) => t(`area.${countryIso}.listName`))
          .sort((a, b) => a.localeCompare(b)),
      })
    } else {
      countryOptionGroups.forEach((group) => {
        if (!Array.isArray((group as OptionsGroup).options)) return

        const regionCountries = (group as OptionsGroup).options.map((option) => option.value as CountryIso)
        const selectedCountriesInRegion = regionCountries.filter((country) => value.includes(country))

        if (selectedCountriesInRegion.length > 0) {
          selectedRegions.push({
            regionLabel: group.label,
            selectedCountries: selectedCountriesInRegion
              .map((country) => t(`area.${country}.listName`))
              .sort((a, b) => a.localeCompare(b)),
          })
        }
      })
    }

    const gridTemplateColumns = `repeat(${selectedRegions.length},1fr)`

    return ReactDOMServer.renderToStaticMarkup(
      <div className="regions-container" style={{ gridTemplateColumns }}>
        {selectedRegions.length > 1 &&
          selectedRegions.map(({ regionLabel }) => (
            <div key={regionLabel} className="region-title">
              {regionLabel}
            </div>
          ))}

        {selectedRegions.map(({ regionLabel, selectedCountries }) => (
          <div key={regionLabel} className="countries-container">
            {selectedCountries.map((countryLabel) => (
              <span key={countryLabel} className="country">
                {countryLabel}
              </span>
            ))}
          </div>
        ))}
      </div>
    )
  }, [canDisplayTooltip, countryOptionGroups, value, error, isPanEuropean, t])

  const hideTooltip = useCallback(() => setCanDisplayTooltip(false), [])
  const showTooltip = useCallback(() => setCanDisplayTooltip(true), [])

  const dataTooltipId = useMemo(() => (error ? TooltipId.error : TooltipId.infoClickable), [error])

  return {
    hideTooltip,
    showTooltip,
    tooltipContent,
    dataTooltipId,
  }
}
